import { ref, useContext, useRoute } from '@nuxtjs/composition-api'
import { BreadcrumbItem } from './ui/useBreadcrumb'
import { SiteName } from './useContent.meta'

export interface SchemaScript {
  type: string
  charset: string
  json: Record<string, any>
}

const BASE_SCHEMA_URL = 'http://schema.org'
const BASE_SCRIPT = {
  type: 'application/ld+json',
  charset: 'utf-8',
} as const
const BASE_SCHEMA = {
  '@context': BASE_SCHEMA_URL,
} as const
// TODO: Reimplement brand exclusion from api <- configs
const EXCLUDED_BRANDS = ["dior","chanel"]

const useCurrentUrl = () => {
  const { $config } = useContext()
  const route = useRoute()

  return `${$config.DOMAIN}${route.value.fullPath}`
}

export const useBreadcrumbsSchemaScript = () => {
  const { localePath } = useContext()
  const route = useRoute()

  const breadcrumbsSchemaScript = ref<null | SchemaScript>(null)

  const setBreadcrumbsSchemaScript = (
    breadcrumbs: BreadcrumbItem[],
    includeHome = false
  ) => {
    if (breadcrumbs.filter(b => EXCLUDED_BRANDS.includes(b.label.toLowerCase()) ).length === 0) {
      const finalBreadcrumbs = includeHome
        ? breadcrumbs
        : [{ label: 'Home', to: { name: 'index' } }, ...breadcrumbs]
      const itemList = finalBreadcrumbs.map((item, index) => ({
        item: {
          '@id': item.to ? `${localePath(item.to)}` : route.value.path,
          name: item.label,
          ...BASE_SCHEMA,
          '@type': 'Thing',
        },
        position: index + 1,
        ...BASE_SCHEMA,
        '@type': 'ListItem',
      }))

      breadcrumbsSchemaScript.value = {
        ...BASE_SCRIPT,
        json: {
          itemListElement: itemList,
          itemListOrder: 'ItemListOrderAscending',
          numberOfItems: finalBreadcrumbs.length,
          ...BASE_SCHEMA,
          '@type': 'BreadcrumbList',
        },
      }
    }
  }

  return {
    breadcrumbsSchemaScript,
    setBreadcrumbsSchemaScript,
  }
}

export const useHomeSchemaScript = () => {
  const { $config, i18n, localePath } = useContext()
  const currentUrl = useCurrentUrl()

  const domain = $config.DOMAIN
  const homeSchemaScripts = ref<SchemaScript[]>([])

  const setHomeSchemaScript = (data: {
    title: string
    phoneNumber: string
  }) => {
    const alternateName = data.title
      ? `${data.title} - HOME - ${SiteName}`
      : SiteName
    const languageCodesStr = `[${i18n.localeCodes.join(',')}]`

    homeSchemaScripts.value = [
      {
        ...BASE_SCRIPT,
        json: {
          name: SiteName,
          alternateName,
          potentialAction: {
            'query-input': {
              multipleValues: false,
              readonlyValue: false,
              valueName: 'search_term_string',
              valueRequired: false,
              ...BASE_SCHEMA,
              '@type': 'PropertyValueSpecification',
            },
            target: {
              urlTemplate: `${domain}${localePath({
                name: 'search-slug',
              })}/{search_term_string}`,
              ...BASE_SCHEMA,
              '@type': 'EntryPoint',
            },
            ...BASE_SCHEMA,
            '@type': 'SearchAction',
          },
          url: currentUrl,
          ...BASE_SCHEMA,
          '@type': 'WebSite',
        },
      },
      {
        ...BASE_SCRIPT,
        json: {
          logo: `${domain}/_nuxt/assets/logo.png`,
          contactPoint: {
            areaServed: languageCodesStr,
            availableLanguage: {
              name: languageCodesStr,
              ...BASE_SCHEMA,
              '@type': 'Language',
            },
            contactOption: 'HearingImpairedSupported',
            contactType: 'customer support',
            telephone: data.phoneNumber,
            ...BASE_SCHEMA,
            '@type': 'ContactPoint',
          },
          name: SiteName,
          sameAs: [],
          url: currentUrl,
          ...BASE_SCHEMA,
          '@type': 'Organization',
        },
      },
    ]
  }

  return {
    homeSchemaScripts,
    setHomeSchemaScript,
  }
}